body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.propertyInfoFormWrapper {
  border-radius: 5px 5px 0px 0px;
  border: 1px solid #2ba8e0;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  margin: 0.75rem;
}

.propertyInfoFormHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 1rem;
  background-color: #2ba8e0;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  margin-bottom: 1rem;
}

.propertyInfoFormBody {
  position: relative;
}

.propertyInfoFormBody .blg-col-0 {
  display: none;
}

.propertyInfoFormBody h6 {
  font-weight: 700;
}

.propertyInfoFormBody .consent-font {
  font-size: 11px;
}

.propertyInfoFormBody .checkIConsent .form-check-label {
  font-weight: 700;
}

.propertyInfoFormBody .blg-row-2 {
  margin-top: -8px;
}

.propertyInfoFormBody h4 {
  font-size: 18px;
}

.propertyInfoFormBody .btn-blg,
.propertyInfoFormBody .btn-start {
  font-size: 13px;
}
/* USER GATEWAY ICON CSS*/
body .uwy.userway_p3 .uai,
body .uwy.userway_p6 .uai,
body .uwy.userway_p7 .uai, 
body .uwy.userway_p8 .uai {
    top: 15px !important;
    left: 450px !important;
}

@media screen and (max-width: 500.7px) {
    body .uwy .uai,
    .uwy .uai img:not(.check_on), body .uwy .uai img:not(.check_on) {
        width: 30px !important;
        height: 30px !important;
        min-width: 30px !important;
        min-height: 30px !important;
        max-width: 30px !important;
        max-height: 30px !important;
        padding: 2px !important;
    }
}
@media screen and (min-width: 992px) {
    body .uwy .uai,
    .uwy .uai img:not(.check_on), body .uwy .uai img:not(.check_on) {
        width: 30px !important;
        height: 30px !important;
        min-width: 30px !important;
        min-height: 30px !important;
        max-width: 30px !important;
        max-height: 30px !important;
        padding: 2px !important;
    }
}
@media screen and (min-width:768px) and (max-width:991.9px) {
    body .uwy .uai,
    .uwy .uai img:not(.check_on), body .uwy .uai img:not(.check_on) {
        width: 25px !important;
        height: 25px !important;
        min-width: 25px !important;
        min-height: 25px !important;
        max-width: 25px !important;
        max-height: 25px !important;
        padding: 2px !important;
    }
}
@media screen and (min-width:320px) and (max-width:340.9px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left:310px !important;
    }
}
@media screen and (min-width:341px) and (max-width:360.9px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left:330px !important;
    }
}
@media screen and (min-width:361px) and (max-width:420.9px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left:360px !important;
    }
}
@media screen and (min-width:421px) and (max-width:465.9px){
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left:25rem !important;
    }
}
@media screen and (min-width:466px) and (max-width:499.9px){
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 25px !important;
        left:28.5rem !important;
    }
}
@media screen and (min-width:500px) and (max-width:575.9px){
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left:30.5rem !important;
    }
}
@media screen and (min-width:576px) and (max-width:699.9px){
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left:35.5rem !important;
    }
}
@media screen and (min-width:700px) and (max-width:767.9px){
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 15px !important;
        left:85% !important;
    }
}
@media screen and (min-width:768px) and (max-width:991.9px){
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 35px !important;
        left:99.5% !important;
    }
}
/* @media screen and (min-width:650px) and (max-width:991.9px){
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 25px !important;
        left:75% !important;
    }
} */
@media screen and (min-width:992px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 30px !important;
        left:72% !important;
    }
}
 @media screen and (min-width:992px) and (max-width:1199.9px){
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 30px !important;
    }
}
@media screen and (min-width:1200px) and (max-width:1599.9px){
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 30px !important;
        left: 70% !important;
    }
}
@media screen and (min-width:1600px) and (max-width:1999.9px){
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 30px !important;
        left: 65% !important;
    }
} 
@media screen and (min-width:2000px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 2% !important;
        left: 61% !important;
    }
}
@media screen and (min-width:3000px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 2% !important;
        left: 58% !important;
    }
}
