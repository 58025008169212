.form-titles-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  padding: 1rem 0;
  border-radius: 5px 5px 0px 0px;
  background-color: #2ba8e0;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  margin-bottom: 2rem;
}
.consent-font {
  font-size: 11px;
}
.tru-approval-border-header {
  border: 1px solid #2ba8e0;
  border-top: none;
  border-bottom: none;
  border-radius: 5px 5px 0px 0px;
}