.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.navbar {
  background: transparent !important;
  z-index: 999;
  box-shadow: none;
  padding-right: 0em;
  padding-top: 1em;
  height: 110px;
  transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}
.navbar.active {
  background: #083f88 !important;
}
.navbar .container{
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width:320px) and (max-width:767.9px) {
  .navbar {
    padding-top: 0em !important;
    height: 80px;
  }
}



@media screen and (min-width:992px) {
.navbarlist {
  background: transparent !important;
  z-index: 999;
  box-shadow: none;
  padding-right: 0em;
  padding-top: 1em;
  height: 150px;
  display: flex !important;
  flex-direction: column !important;
  transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}
.imagesection{
    margin-top: 10rem;
}
.navbarlist.active {
  background: #FFFFFF !important;
}
.navbarlist .container{
  display: flex !important;
  justify-content: center !important;
  align-items: center;
}
}
@media screen and (min-width:768px) and (max-width:991.9px){
.navbarlist {
  background: transparent !important;
  z-index: 999;
  box-shadow: none;
  padding-right: 0em;
  padding-top: 1em;
  height: 175px;
  flex-wrap: nowrap !important;
  display: flex !important;
  flex-direction: column !important;
  transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}
.imagesection{
    margin-top: 11rem;
}
.navbarlist.active {
  background: #FFFFFF !important;
}
.navbarlist .container{
  display: flex !important;
  justify-content: center !important;
  align-items: center;
}
}
@media screen and (min-width:320px) and (max-width:767.9px) {
  .navbarlist {
  height: 140px;
  display: flex !important;
  flex-direction: column !important;
}
.navbarlist.active {
  background: #FFFFFF !important;
}
.navbarlist .container{
  display: flex !important;
  justify-content: center !important;
  align-items: center;
}
}