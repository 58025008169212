@import url("https://fonts.googleapis.com/css2?family=Redressed&display=swap");
.site footer {
  padding: 0 0 2rem 0;
}
.banner {
  width: 100%;
  height: 25rem;
  background-image: url("https://d2w24n4g34usfg.cloudfront.net/property-listings/img/search-desktop-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
}

.logo {
  max-height: 5rem;
  width: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.banner_title {
  font-family: "Redressed";
  font-style: normal;
  font-weight: 400;
  font-size: 70px;
  text-align: center;
  color: #ffffff;
}

.search_box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  background: #ffffff;
  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.25);
  border-radius: 0 0 20px 20px;
  width: 100%;
}

.listings_number {
  background: #009fe0;
  border-radius: 16.1485px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  color: #ffffff;
  padding: 0.5rem 1rem;
  position: relative;
  margin-top: -1.5rem;
  margin-bottom: 1.5rem;
}
.search_input_box {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row nowrap;
  width: calc(100% - 4rem);
  height: 2.5rem;
  margin: 0 2rem 2rem 2rem;
  border: 1px solid #009fe0;
  border-radius: 5px;
}

.search_input {
  all: unset;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: #ffffff;
  padding-left: 1rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #009fe0;
}
.search_input::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #009fe0;
}
.search_icon {
  border-radius: 4px;
  height: 100%;
  width: auto;
  background-color: #009fe0;
  padding: 0.5rem;
}

.sort_toggle {
  all: unset;
}

.sort_toggle,
.arrow,
.no_match {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #009fe0;
  cursor: pointer;
  transition: color 0.4s, transform 0.4s;
}
.no_match {
  cursor: unset;
}
.arrow {
  display: inline-block;
  margin-left: 0.25rem;
}

.sort_toggle + .arrow {
  transform: rotate(90deg);
}
.sort_toggle:before {
  content: "Sort: ";
}
.sort_toggle:after {
  content: "Newest";
}
.sort_toggle:checked:after {
  content: "Oldest";
}

.sort_toggle:checked + .arrow {
  transform: rotate(-90deg);
}
.sort_toggle_box:hover :where(.arrow, .sort_toggle) {
  color: #06397b;
}
.listing_date {
  background: rgba(88, 88, 88);
  border-radius: 0px 20px 20px 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: fit-content;
  padding: 0 1.25rem 0 0.5rem;
  color: #ffffff;
  position: absolute;
  margin-top:2.6rem;
}
.card_image {
  background: #d9d9d9;

  border-radius: 5px;
}
.card {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0.5rem 0;
}
.card_content_box {
  width: calc(100% - 2rem);
  background: #ffffff;
  box-shadow: 0px 0px 5.04505px 5.04505px rgba(0, 0, 0, 0.15);
  border-radius: 10.0901px;
  padding: 0.5rem;
  position: relative;
  margin-top: -2.5rem;
  margin-left: 1rem;
}
.offer_price {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #2d388c;
}

.rooms {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2d388c;
}
.address {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #6f6f6f;
}
.link {
  text-decoration: none;
}
.page .page_item :is(a) {
  border-color: transparent;
  color: #06397b;
}
.page .page_item :is(a, span) {
  width: 2rem;
  height: 2rem;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
}
.page .page_item :is(span:first-of-type, span:last-of-type) {
  color: #009fe0;
  background: transparent;
  border-radius: 3px;
}
.page .page_item.active :is(a, span) {
  border-color: #009fe0;
}
.page .page_arrow {
  margin: -0.05rem 0.5rem 0 0.5rem;
}
.page {
  margin-top: 1rem;
}
.page .page_arrow span {
  font-size: 22px;
  background-color: transparent;
  line-height: 100%;
}
@media (max-width: 767px) {
  .banner {
    background-image: url("https://d2w24n4g34usfg.cloudfront.net/property-listings/img/search-mobile-banner.png");
    height: 25rem;
    justify-content: flex-start;
    background-size: cover;
  }
  .logo {
    max-width: 65%;
    max-height: 60%;
  }
  .banner_title {
    line-height: 100%;
    position: relative;
    margin-top: 8rem;
  }
  .search_box {
    box-shadow: none;
    border-radius: 0;
    width: 100%;
  }
  .sort_border {
    border-bottom: 3px solid rgba(0, 0, 0, 0.25);
  }
}

@media (max-width: 525px) {
  .site footer {
    padding: 0 0 4.5rem 0;
  }
}

@media (max-width: 350px) {
  .banner_title {
    font-size: 60px;
    margin-top: 6rem;
  }
}

