body {
  background-color: #f8f8f8;
  font-family: "Poppins" !important;
}

img {
  width: 100%;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.padding-top-bottom-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.padding-10px {
  padding: 10px;
}

.padding-left-right-10 {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.padding-left-right-bottom-10 {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 10px;
}

.padding-left-10px {
  padding-left: 10px;
}

.padding-left-right-10px {
  padding-left: 10px;
  padding-right: 10px;
}

.text-justify {
  text-align: justify;
}

.no-border-image {
  border: none !important;
}

.text-normal {
  font-weight: normal !important;
}

.float-right {
  float: right;
}

.company-logo {
  max-width: 150px !important;
}

.profile-img {
  max-width: 150px !important;
  object-fit: cover;
}

.tru-approval-contact-team-img {
  max-width: 300px;
  object-fit: cover;
}

.staff-card {
  border-left: 4px solid #2ba8e0 !important;
}

.staff-card-body {
  padding: 0rem 1rem 0rem 1rem !important;
}

.staff-btn-padding {
  padding-top: 10px;
}

.staff-name {
  color: #00aae4;
}

.staff-text {
  color: #00208b;
}

.staff-img-left {
  text-align: left;
}

.staff-img-right {
  text-align: right;
}

.staff-text-align-left {
  text-align: left;
}

.staff-text-align-right {
  text-align: right;
}

.staff-btn {
  width: 100%;
  font-weight: bold !important;
  background-color: #00aae4 !important;
  border-color: #00aae4 !important;
}

.staff-btn:hover {
  background-color: #00208b !important;
  border-color: #00208b !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.property-featured {
  width: 87px;
  height: 24px;
  background: #ff6935;
  border-radius: 2px;
}

.property-featured-text {
  font-style: normal;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.property-for-sale {
  width: 87px;
  height: 24px;
  background: #0ec6d5;
  border-radius: 2px;
}

.property-for-sale-text {
  font-style: normal;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.property-sold-out {
  width: 87px;
  height: 24px;
  background: #ff5a50;
  border-radius: 2px;
}

.property-sold-out-text {
  font-style: normal;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.property-address {
    font-weight: bold;
    color: #191919;
    font-size: 1.5rem;
}

.overlay-box {
  position: relative;
  height: 100%;
  width: 100%;
}

.overlay-box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, #2d388c 0%, #2d388c 70%);
  opacity: 0.7;
}

#btn-img-wrapper {
  position: relative;
}

#btn-img-wrapper button {
  position: absolute;
  bottom: 5px;
}

.btn-tru-approval {
  font-size: 0.7rem !important;
}

.btn-start-application {
  font-size: 0.7rem !important;
}

.sun-west-white-logo {
  position: absolute;
  top: 55%;
}

.sun-west-white-logo-img {
  max-width: 400px;
}

.sun-west-house-logo {
  position: absolute;
  top: 30%;
}

.sun-west-house-logo-img {
  max-width: 130px;
}

.property-sold-msg {
  position: absolute;
  top: 45%;
  color: #fff;
}

.property-sold-msg-h2 {
  font-size: calc(1.125rem + 0.7vw) !important;
}

.property-sold-text {
  position: absolute;
  top: 50%;
  color: #fff;
}

.listed-by {
  color: #757575;
}

.listed-by li h6 {
  font-size: 0.8rem !important;
}

.listed-by-list-inline {
  padding-left: 0;
  list-style: none;
}

.listed-by-list-inline-item {
  display: inline-block;
}

.listed-by-list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.featherclock-container {
  align-items: center;
  display: flex;
  margin-left: 20px;
  min-width: 130px;
}

.featherclock-div {
  align-items: center;
  display: flex;
  height: 24px;
  padding: 0 1px;
  width: 24px;
}

.featherclock-group {
  align-items: flex-start;
  background-image: url(https://d2w24n4g34usfg.cloudfront.net/property-listings/img/clock-circle.svg);
  background-size: 100% 100%;
  display: flex;
  height: 22px;
  justify-content: flex-end;
  min-width: 22px;
  padding: 4px 6px;
}

.featherclock-hand {
  height: 10px;
  width: 6px;
}

.time-when-listed {
  color: #757575;
}

.property-price {
  font-weight: bold;
  text-align: right;
  color: #191919;
}

.property-price-label {
  color: #757575;
  font-size: 0.8rem !important;
  text-align: right;
}

.property-header-buttons-group {
  display: flex;
  justify-content: flex-end;
}

.property-header-buttons {
  letter-spacing: 0;
  white-space: nowrap;
  color: #15b1e6 !important;
  border: 1px solid #e5e5e5 !important;
  width: 95px;
}

.property-header-buttons:hover {
  color: #15b1e6 !important;
  background-color: transparent !important;
  border: 1px solid #15b1e6 !important;
}

.heart-icon {
  height: 18px;
  width: 20px;
}

.share-icon-container {
  height: 20px;
  position: relative;
  width: 18px;
  margin-top: 3px;
}

.share-icon-1 {
  height: 8px;
  left: 10px;
  position: absolute;
  top: 0;
  width: 8px;
}

.share-icon-2 {
  height: 8px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 8px;
}

.share-icon-3 {
  height: 8px;
  left: 10px;
  position: absolute;
  top: 12px;
  width: 8px;
}

.share-icon-4 {
  height: 6px;
  left: 5px;
  position: absolute;
  top: 10px;
  width: 8px;
}

.share-icon-5 {
  height: 6px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 8px;
}

.share-icon-text {
  margin-top: 3px;
}

.print-icon-container {
  height: 20px;
  position: relative;
  width: 20px;
  margin-top: 3px;
}

.print-icon-1 {
  height: 9px;
  left: 4px;
  position: absolute;
  top: 0;
  width: 12px;
}

.print-icon-2 {
  height: 10px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 20px;
}

.print-icon-3 {
  height: 10px;
  left: 4px;
  position: absolute;
  top: 10px;
  width: 12px;
}

.print-icon-text {
  margin-top: 3px;
}

.emailThisHomeShareLink {
  text-decoration: none !important;
}

.emailThisHomeShareLink:hover {
  text-decoration: underline !important;
}

.see-all-photos-btn {
  margin-top: -5rem !important;
  margin-left: 1rem !important;
  background-color: #fff !important;
}

.see-all-photos-btn:hover {
  background-color: #6c757d !important;
  color: #fff !important;
}

.property-image-1 {
  background-color: #2d388c !important;
  opacity: 0.7;
  z-index: 1;
}

.property-description-header {
  font-weight: bold;
  color: #191919;
}

.property-description-text {
  color: #757575;
  text-align: justify;
}

.prop-full-desc {
  display: none;
}

.prop-desc-btn-link {
  text-decoration: none !important;
}

.prop-desc-btn-link:hover {
  text-decoration: underline !important;
}

.facts-and-features-header {
  font-weight: bold;
  color: #191919;
}

.facts-and-features-icon-label {
  color: #757575;
  font-size: 0.8rem !important;
}

.facts-and-features-icon-group {
  align-items: center;
  display: flex;
  min-width: 182px;
}

.facts-and-features-icon-border {
  border: 1px solid #e5e5e5 !important;
  padding: 10px;
}

.stay-at-home-group {
  align-items: flex-end;
  display: flex;
  height: 36px;
  overflow: hidden;
  padding: 0.7px 0;
  width: 36px;
}

.stay-at-home-container {
  height: 34px;
  position: relative;
  width: 36px;
}

.stay-at-home-icon-1 {
  height: 8px;
  left: 15px;
  position: absolute;
  top: 14px;
  width: 6px;
}

.stay-at-home-icon-2 {
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 36px;
}

.stay-at-home-icon-3 {
  height: 29px;
  left: 3px;
  position: absolute;
  top: 5px;
  width: 30px;
}

.year-built-group {
  align-items: flex-end;
  display: flex;
  height: 36px;
  justify-content: center;
  overflow: hidden;
  width: 36px;
}

.hotel-icon-1 {
  height: 24px;
  margin-bottom: -0.47px;
  width: 7px;
}

.hotel-icon-2-3-group {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: -0.31px;
  margin-left: 2px;
  min-height: 37px;
  width: 18px;
}

.hotel-icon-2 {
  height: 4px;
  margin-left: 2px;
  width: 14px;
}

.hotel-icon-3-group {
  align-items: flex-end;
  background-image: url(https://d2w24n4g34usfg.cloudfront.net/property-listings/img/hotel-icon-5.svg);
  background-size: 100% 100%;
  display: flex;
  height: 31px;
  justify-content: flex-end;
  margin-top: 2px;
  min-width: 18px;
  padding: 0.2px 6px;
}

.hotel-icon-3 {
  height: 8px;
  width: 6px;
}

.hotel-icon-4 {
  height: 24px;
  margin-bottom: -0.47px;
  margin-left: 2px;
  width: 7px;
}

.single-bed-group,
.air-conditioner-group {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 36px;
  overflow: hidden;
  width: 36px;
}

.single-bed-container {
  height: 16px;
  margin-left: 1px;
  position: relative;
  width: 34px;
}

.single-bed-icon-1 {
  height: 6px;
  left: 11px;
  position: absolute;
  top: 10px;
  width: 12px;
}

.single-bed-icon-2 {
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 34px;
}

.single-bed-icon-3 {
  height: 6px;
  margin-left: 3px;
  margin-top: 1px;
  width: 30px;
}

.single-bed-icon-4 {
  height: 12px;
  margin-top: 1px;
  width: 36px;
}

.shower-group {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 36px;
  overflow: hidden;
  width: 36px;
}

.shower-icon-1 {
  height: 19px;
  margin-left: 1.69px;
  width: 35px;
}

.shower-icon-2 {
  height: 6px;
  margin-right: 7.88px;
  margin-top: 2px;
  width: 29px;
}

.shower-icon-3-4-5-container {
  align-items: flex-start;
  display: flex;
  margin-right: 7.88px;
  margin-top: 1px;
  min-width: 16px;
}

.shower-icon-3 {
  height: 4px;
  width: 3px;
}

.shower-icon-4 {
  height: 4px;
  margin-left: 3px;
  width: 4px;
}

.shower-icon-5 {
  height: 4px;
  margin-left: 3px;
  width: 3px;
}

.shower-icon-6-7-8-9-container {
  align-items: flex-start;
  display: flex;
  margin-right: 7.81px;
  margin-top: 1px;
  min-width: 24px;
}

.shower-icon-6 {
  height: 4px;
  width: 4px;
}

.shower-icon-7 {
  height: 4px;
  margin-left: 3px;
  width: 3px;
}

.shower-icon-8,
.shower-icon-9 {
  height: 4px;
  margin-left: 4px;
  width: 3px;
}

.heating-icon-1-2-container {
  height: 21px;
  position: relative;
  width: 36px;
}

.heating-icon-1 {
  height: 6px;
  left: 7px;
  position: absolute;
  top: 15px;
  width: 22px;
}

.heating-icon-2 {
  height: 21px;
  left: 0;
  position: absolute;
  top: 0;
  width: 36px;
}

.heating-icon-3-4-5-6-7-container {
  align-items: flex-end;
  display: flex;
  margin-left: 3.81px;
  min-width: 29px;
}

.heating-icon-3 {
  align-self: flex-start;
  height: 13px;
  width: 7px;
}

.heating-icon-4 {
  align-self: center;
  height: 11px;
  margin-bottom: 0.34px;
  margin-left: 1px;
  width: 3px;
}

.heating-icon-5-6-container {
  height: 15px;
  margin-left: 1px;
  position: relative;
  width: 8px;
}

.heating-icon-5 {
  height: 12px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 7px;
}

.heating-icon-6 {
  height: 9px;
  left: 5px;
  position: absolute;
  top: 0;
  width: 3px;
}

.heating-icon-7 {
  height: 13px;
  margin-bottom: 0.06px;
  margin-left: 2px;
  width: 7px;
}

.garage-group {
  align-items: flex-start;
  display: flex;
  height: 36px;
  justify-content: center;
  overflow: hidden;
  padding: 1px 0;
  width: 36px;
}

.garage-icon {
  background-image: url(https://d2w24n4g34usfg.cloudfront.net/property-listings/img/garage-icon.svg);
  background-size: 100% 100%;
  height: 34px;
  width: 36px;
}

.sq-ft-group {
  align-items: flex-start;
  display: flex;
  height: 36px;
  overflow: hidden;
  padding: 1px 0;
  width: 36px;
}

.sq-ft-container {
  height: 34px;
  position: relative;
  width: 36px;
}

.sq-ft-icon-1 {
  height: 10px;
  left: 27px;
  position: absolute;
  top: 12px;
  width: 9px;
}

.sq-ft-icon-2 {
  height: 34px;
  left: 0;
  position: absolute;
  top: 0;
  width: 33px;
}

.sq-ft-icon-3 {
  height: 9px;
  left: 5px;
  position: absolute;
  top: 20px;
  width: 8px;
}

.sq-ft-icon-4 {
  height: 9px;
  left: 20px;
  position: absolute;
  top: 5px;
  width: 8px;
}

.verified-by-group {
  align-items: flex-start;
  display: flex;
  overflow: hidden;
  padding: 0 5px;
  width: 36px;
}

.verified-by-container {
  height: 36px;
  margin-top: -0.24px;
  position: relative;
  width: 26px;
}

.verified-by-icon-1 {
  height: 34px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 26px;
}

.verified-by-icon-2 {
  height: 5px;
  left: 7px;
  position: absolute;
  top: 0;
  width: 12px;
}

.appraisal-group-border {
  border: 1px solid #e5e5e5 !important;
  padding: 5px;
  width: 58px;
  height: 57.7px;
}

.appraisal-group {
  align-items: flex-start;
  display: flex;
  overflow: hidden;
  width: 50px;
}

.appraisal-container {
  height: 50px;
  margin-top: -0.24px;
  position: relative;
  width: 50px;
}

.appraisal-icon {
  height: 50px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 50px;
}

.google-maps-border {
  border: 1px solid #2ba8e0;
}

.additional-details-header {
  font-weight: bold;
  color: #191919;
}

.additional-details-label {
  font-weight: bold;
  font-size: 0.8rem !important;
  color: #191919;
}

.additional-details-text {
  color: #757575;
  font-size: 0.8rem !important;
}

.amenities-header {
  font-weight: bold;
  color: #191919;
}

.amenities-check-box-group {
  align-items: center;
  display: flex;
  height: 24px;
  padding: 0 3px;
  width: 24px;
}

.amenities-check-box-container {
  height: 18px;
  position: relative;
  width: 18px;
}

.amenities-check-box-1 {
  height: 1px;
  left: 9px;
  position: absolute;
  top: 9px;
  width: 1px;
}

.amenities-check-box-2 {
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 18px;
}

.amenities-label {
  font-weight: bold;
  font-size: 0.8rem !important;
  color: #191919;
}

.property-ul-list {
  color: #414042;
}

.property-icon-text {
  color: #414042;
}

.property-prop-info {
  color: #414042;
}

.icon-group {
  align-items: center;
  align-self: flex-end;
  display: flex;
  height: 67px;
  min-width: 321px;
}

.icon {
  align-items: flex-start;
  display: flex;
  height: 59px;
  overflow: hidden;
  width: 59px;
}

.icon-vector {
  height: 60px;
  margin-top: -0.48px;
  width: 60px;
}

.icon-group-text {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 67px;
  width: 245px;
}

.built-icon-1 {
  height: 28px;
  position: absolute;
  width: 33px;
}

.built-icon-2 {
  height: 21px;
  left: 32px;
  position: absolute;
  top: 30px;
  width: 21px;
}

.per-sqft-frame {
  align-items: flex-start;
  display: flex;
  height: 33px;
  overflow: hidden;
  width: 59px;
}

.per-sqft-container {
  align-items: flex-start;
  background-image: url(https://d2w24n4g34usfg.cloudfront.net/property-listings/img/per-sqft-icon.svg);
  background-size: 100% 100%;
  display: flex;
  height: 34px;
  justify-content: flex-end;
  margin-top: -0.48px;
  min-width: 59px;
  padding: 8.1px 9px;
}

.per-sqft-icon-1 {
  height: 6px;
  width: 4px;
}

.per-sqft-icon-2 {
  height: 15px;
  margin-left: 7px;
  margin-top: 0.43px;
  width: 16px;
}

.built-icon-frame {
  align-items: flex-start;
  display: flex;
  height: 50px;
  margin-bottom: 3px;
  overflow: hidden;
  width: 59px;
}

.built-icon-container {
  height: 51px;
  margin-left: -1px;
  margin-top: -0.56px;
  position: relative;
  width: 60px;
}

.built-icon {
  height: 51px;
  left: 10px;
  position: absolute;
  top: 0;
  width: 50px;
}

.built-icon-1 {
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 33px;
}

.built-icon-2 {
  height: 21px;
  left: 32px;
  position: absolute;
  top: 30px;
  width: 21px;
}

.callme-modal {
  border: none;
  height: 200px;
  width: 100%;
}

.textme-modal {
  border: none;
  height: 275px;
  width: 100%;
}

.table {
  border-color: #2d388c !important;
}

.table > :not(:first-child) {
  border-top: none !important;
}

.loan-scenario-table-head {
  background-color: #2d388c;
  color: white;
}

.loan-scenario-program {
  background-color: #15b1e6 !important;
  color: white;
}

.loan-scenario-text {
  color: #2d388c;
  font-weight: bold;
}

.loan-scenario-header-disclaimer {
  color: #414042;
  font-size: 11px !important;
  font-weight: bold;
}

.loan-scenario-buydown-border {
  border-top: 1px solid #2d388c;
}

.loanScenarioTopBorderHidden {
  border-top-style: hidden;
}

.permanentBuyDown {
  color: #02a145;
  font-style: normal;
  font-weight: bold;
}

.twoOneBuyDown {
  color: #fea004;
  font-style: normal;
  font-weight: bold;
}

.threeTwoOneBuyDown {
  color: #15b1e6;
  font-style: normal;
  font-weight: bold;
}

.disclaimer-font {
  font-size: 11px !important;
  color: #083f88;
}

.error-text {
  color: #00208b;
}

.error-text-link {
  color: #00aae4 !important;
}

.scrollToTop {
  padding: 10px;
}

.overRideTransition {
  color: #23527c;
}

#callMeModal .modal-header,
#textMeModal .modal-header {
  background: #2ba8e0;
  color: #fff;
  font-style: normal;
  font-weight: 600;
}

#listingFlyerPrint {
  font-family: "Poppins" !important;
}

#listingFlyerPrint .headingBg {
  background: #2d388c;
}

#listingFlyerPrint .printHeadingPadding {
  /* padding-left: 0.75rem;
    padding-right: 0.75rem; */
}

#listingFlyerPrint .printPropAddress {
  font-style: normal;
  font-weight: 700;
  color: #15b1e6;
}
#listingFlyerPrint .propDescBox {
    height:100%;
    max-height:10rem;
    overflow:hidden;
    display: block;

}
#listingFlyerPrint .propDescBox .heading4 {
  font-size: calc(1.25rem + 2px) !important;
}

#listingFlyerPrint .propDescBox .heading6 {
  font-size: calc(0.65rem + 2px) !important;
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;

}

#listingFlyerPrint .printOfferPrice {
  font-style: normal;
  font-weight: 700;
  color: #fff;
}

#listingFlyerPrint .printPropDesc {
  font-style: normal;
  font-weight: 500;
  color: #000000;
  opacity: 0.8;
  
}

#listingFlyerPrint .heading3 {
  font-size: 1.5rem !important;
}

#listingFlyerPrint .heading4 {
  font-size: calc(1.25rem) !important;
}

#listingFlyerPrint .heading5 {
  font-size: 1rem !important;
}

#listingFlyerPrint .heading6 {
  font-size: calc(0.65rem) !important;
}

#listingFlyerPrint .heading7 {
  font-size: 11px !important;
}

#listingFlyerPrint .loan-scenario-table-head-print {
  background-color: #2d388c;
  color: white;
  font-size: 11px !important;
}

#listingFlyerPrint .loan-scenario-program-print {
  background-color: #15b1e6 !important;
  color: white;
  font-size: 10px !important;
}

#listingFlyerPrint .loan-scenario-text-print {
  color: #2d388c;
  font-weight: bold;
  font-size: 10px !important;
}

#listingFlyerPrint .company-logo-print {
  height: 100%;
  width: auto;
}

#listingFlyerPrint .profile-img-print {
  width: 150px !important;
  height: 200px !important;
  object-fit: cover;
  border: 2px solid #15b1e6;
}

#listingFlyerPrint .disclaimer-font-print {
  font-size: 9px !important;
  line-height: 9px;
}
#listingFlyerPrint .company-logo-box-print {
  height: 50px;
  width: auto;
}
#listingFlyerPrint .prop-image-main {
  width: 100%;
  max-height: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2d388c;
}

#listingFlyerPrint .prop-image-sub {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #2d388c;
  max-height: 100%;
  height: 100px;
}

#listingFlyerPrint .prop-image-row {
  padding-bottom: 0.25rem;
  background-color: #2d388c;
  display: flex;
  justify-content: center;
  align-items: center;
}
#listingFlyerPrint .white-space-nowrap {
  white-space: nowrap;
}
.offscreen {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 900px;
  height: auto;
  overflow: hidden;
}
.fb_share {
  position: relative;
  margin-left: 0.75rem;
  transform: scale(1.1);
  margin-top: 0.5rem;
}
.fb_follow {
  overflow: hidden;
  height: 2rem;
  width: 6.7rem;
}
.fb_follow iframe {
  position: relative;
  margin-left: -4rem;
  margin-top: -2rem;
}
.twt_box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #1da1f2;
  border-radius: 10px;
  height: 1.5rem;
  margin: 0 0.25rem;
  padding: 0.25rem 1rem;
  text-decoration: none;
  color: white;
  font-size: 12px;
  white-space: nowrap;
}

.twt_icon {
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
}

@media only screen and (min-width: 992px) {
  .pad-left-2 {
    padding-left: 0.5rem !important;
  }

  .pad-left-3 {
    padding-left: 1rem !important;
  }

  .pad-right-2 {
    padding-right: 0.5rem !important;
  }

  .pad-top-2 {
    padding-top: 0.5rem !important;
  }

  .pad-top-3 {
    padding-top: 1rem !important;
  }

  .pad-top-4 {
    padding-top: 1.5rem !important;
  }

  .pad-top-5 {
    padding-top: 2rem !important;
  }

  .pad-top-6 {
    padding-top: 2.5rem !important;
  }

  .text-right {
    text-align: right;
  }

  .row-reverse {
    flex-flow: row-reverse;
  }
}

@media only screen and (min-width: 768px) {
  .form-titles-section {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .profile-img {
    height: 200px !important;
  }

  .tru-approval-contact-team-img {
    max-width: 200px;
  }

  .buyDownInlineChkBox {
    display: inline-block !important;
    margin-right: 1rem !important;
  }
  #emailThisHomeModal,
  #callMeModal,
  #textMeModal {
    margin-top: 11rem;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .pad-left-4 {
    padding-left: 1.5rem !important;
  }

  .pad-right-4 {
    padding-right: 1.5rem !important;
  }

  .pad-top-2 {
    padding-top: 0.5rem !important;
  }

  .contact-card {
    text-align: center;
  }

  .pt-tab-3 {
    padding-top: 1rem !important;
  }

  .sun-west-white-logo-img {
    max-width: 250px !important;
  }

  .property-sold-msg-h2 {
    font-size: 1.1rem !important;
  }

  .loan-scenario-text {
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 991px) {
  .column-truapproval-reverse {
    flex-flow: column-reverse;
  }

  .sun-west-house-logo-img {
    max-width: 100px;
  }
}
.facts-and-features-header1 {
  font-weight: bold;
  color: #191919;
}

@media only screen and (min-width: 320px) and (max-width: 375px) {
  .moveleft {
    margin-left: 2rem !important;
  }
}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .facts-and-features-icon-group {
    margin-left: 4.5rem;
  }
  .moveleft {
    margin-left: 3.2rem !important;
  }
}

@media only screen and (min-width: 426px) and (max-width: 500px) {
  .facts-and-features-icon-group {
    margin-left: 5.5rem;
  }
  .moveleft {
    margin-left: 3.2rem !important;
  }
}

@media only screen and (min-width: 501px) and (max-width: 600px) {
  .facts-and-features-icon-group {
    margin-left: 8.5rem;
  }
  .moveleft {
    margin-left: 3.2rem !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 767px) {
  .facts-and-features-icon-group {
    margin-left: 8.5rem;
  }
  .moveleft {
    margin-left: 3.2rem !important;
  }
}
@media only screen and (max-width: 767.9px) {
  .property-address {
    text-align: center;
    margin-top: 8.25rem !important;
  }
  .facts-and-features-header1 {
    font-weight: bold;
    color: #191919;
  }

  .facts-and-features-icon-label {
    font-size: 1rem !important;
  }
  .listed-by {
    text-align: center;
  }

  .property-price {
    text-align: center !important;
  }

  .property-price-label {
    text-align: center !important;
  }

  .disclaimer-font {
    font-size: 10px !important;
  }

  .contact-card {
    text-align: center;
  }

  .pt-mob-2 {
    padding-top: 0.5rem !important;
  }

  .pt-mob-3 {
    padding-top: 1rem !important;
  }

  .mt-mob-2 {
    margin-top: 0.5rem !important;
  }

  .mt-mob-3 {
    margin-top: 1rem !important;
  }

  .mb-mob-2 {
    margin-bottom: 0.5rem !important;
  }

  .staff-img-left {
    text-align: center !important;
  }

  .staff-img-right {
    text-align: center !important;
  }

  .padding-top-bottom-0 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .form-titles-section {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .property-header-content {
    justify-content: center;
  }

  .property-header-buttons-group {
    justify-content: center;
    margin-top: 0.5rem !important;
  }

  .sun-west-white-logo-img {
    max-width: 225px !important;
  }

  .property-sold-msg-h2 {
    font-size: 1rem !important;
  }

  .loan-scenario-text {
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 376px) {
  .sun-west-white-logo-img {
    max-width: 200px !important;
  }
}

@media only screen and (max-width: 321px) {
  .sun-west-white-logo-img {
    max-width: 170px !important;
  }

  .property-sold-msg-h2 {
    font-size: 0.8rem !important;
  }
}

@media print {
  @page {
    size: A4 portrait;
    margin: 6mm;
  }
  .pagebreak {
    page-break-before: always;
  }
}

@media screen and (min-width: 992px) {
  .navbarlist {
    background: transparent !important;
    z-index: 999;
    box-shadow: none;
    padding-right: 0em;
    padding-top: 1em;
    height: 150px;
    display: flex !important;
    flex-direction: column !important;
    transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
      width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  }
  .imagesection {
    margin-top: 10rem;
  }
  .navbarlist.active {
    background: #ffffff !important;
  }
  .navbarlist .container {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 991.9px) {
  .navbarlist {
    background: transparent !important;
    z-index: 999;
    box-shadow: none;
    padding-right: 0em;
    padding-top: 1em;
    height: 170px;
    flex-wrap: nowrap !important;
    display: flex !important;
    flex-direction: column !important;
    transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
      width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  }
  .imagesection {
    margin-top: 11rem;
  }
  .navbarlist.active {
    background: #ffffff !important;
  }
  .navbarlist .container {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
  }
}
@media screen and (min-width: 320px) and (max-width: 767.9px) {
  .navbarlist {
    height: 140px;
    display: flex !important;
    flex-direction: column !important;
  }
  .navbarlist.active {
    background: #ffffff !important;
  }
  .navbarlist .container {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
  }
  #emailThisHomeModal,
  #propertyImagesModal,
  #callMeModal,
  #textMeModal {
    margin-top: 9rem;
  }
}
@media screen and (max-width: 500px) {
  .share-row.row .col-6 {
    width: 100% !important;
    justify-content: space-around !important;
    margin-bottom: 1rem;
  }
  .share-row.row .col-6:last-of-type {
    margin-bottom: unset;
  }
  .fb_share {
    margin-left: 2rem;
  }
  .fb_follow {
    margin-left: 0.5rem;
  }
  .fb_follow iframe {
    position: relative;
    margin-left: -4rem;
    margin-top: -2rem;
  }
}
